import Vue from 'vue';
import PopupApp from './PopupApp.vue';
import ListApp from './ListApp.vue';

Vue.config.productionTip = false;

if (document.getElementById('bhCookiePopup')) {
  new Vue({
    render: (h) => h(PopupApp),
  }).$mount('#bhCookiePopup');
}

if (document.getElementById('bhCookieList')) {
  new Vue({
    render: (h) => h(ListApp),
  }).$mount('#bhCookieList');
}
