<template>
    <div class="bh-cookies-popup-intro-cookies-list">
        <section
            class="bh-cookies-popup-functional bh-cookies-popup-block"
            v-if="countCategory('functional') != 0"
        >
            <h2
                class="bh-cookies-popup-intro-cookies-list-category-heading bh-cookies-popup-highlight-color"
            >
                {{ findMapping("categoryFunctionalHeadline") }}
            </h2>
            <p
                class="bh-cookies-popup-intro-cookies-list-description"
                v-html="findMapping('categoryFunctionalDescription')"
            ></p>

            <template v-for="cookie in storage.cookies">
                <div v-if="cookie.category == 'functional'" v-bind:key="cookie">
                    <div class="bh-cookies-popup-intro-cookies-list-cookie">
                        <div
                            class="bh-cookies-popup-intro-cookies-list-cookie-icon bh-font-cookie bh-cookies-popup-highlight-color"
                            aria-hidden="true"
                        ></div>
                        <hgroup
                            class="bh-cookies-popup-intro-cookies-list-cookie-infos"
                        >
                            <h3
                                class="bh-cookies-popup-intro-cookies-list-cookie-id"
                            >
                                {{ cookie.name }}
                            </h3>
                            <p
                                class="bh-cookies-popup-intro-cookies-list-cookie-name"
                            >
                                {{ findCookieInformation(cookie.name, "name") }}
                                / {{ findMapping("validity") }}:
                                {{ findMapping(cookie.validityReadable) }}
                            </p>
                            <p
                                class="bh-cookies-popup-intro-cookies-list-cookie-description"
                            >
                                {{
                                    findCookieInformation(
                                        cookie.name,
                                        "description"
                                    )
                                }}
                            </p>
                        </hgroup>
                    </div>
                </div>
            </template>
        </section>

        <section
            class="bh-cookies-popup-performance bh-cookies-popup-block"
            v-if="countCategory('performance') != 0"
        >
            <h2
                class="bh-cookies-popup-intro-cookies-list-category-heading bh-cookies-popup-highlight-color"
            >
                {{ findMapping("categoryPerformanceHeadline") }}
            </h2>
            <p
                class="bh-cookies-popup-intro-cookies-list-description"
                v-html="findMapping('categoryPerformanceDescription')"
            ></p>

            <template v-for="cookie in storage.cookies">
                <div
                    v-if="cookie.category == 'performance'"
                    v-bind:key="cookie"
                >
                    <div class="bh-cookies-popup-intro-cookies-list-cookie">
                        <div
                            class="bh-cookies-popup-intro-cookies-list-cookie-icon bh-font-cookie bh-cookies-popup-highlight-color"
                            aria-hidden="true"
                        ></div>
                        <hgroup
                            class="bh-cookies-popup-intro-cookies-list-cookie-infos"
                        >
                            <h3
                                class="bh-cookies-popup-intro-cookies-list-cookie-id"
                            >
                                {{ cookie.name }}
                            </h3>
                            <p
                                class="bh-cookies-popup-intro-cookies-list-cookie-name"
                            >
                                {{ findCookieInformation(cookie.name, "name") }}
                                / {{ findMapping("validity") }}:
                                {{ findMapping(cookie.validityReadable) }}
                            </p>
                            <p
                                class="bh-cookies-popup-intro-cookies-list-cookie-description"
                            >
                                {{
                                    findCookieInformation(
                                        cookie.name,
                                        "description"
                                    )
                                }}
                            </p>
                        </hgroup>
                    </div>
                </div>
            </template>
        </section>

        <section
            class="bh-cookies-popup-tracking bh-cookies-popup-block"
            v-if="countCategory('tracking') != 0"
        >
            <h2
                class="bh-cookies-popup-intro-cookies-list-category-heading bh-cookies-popup-highlight-color"
            >
                {{ findMapping("categoryTrackingHeadline") }}
            </h2>
            <p
                class="bh-cookies-popup-intro-cookies-list-description"
                v-html="findMapping('categoryTrackingDescription')"
            ></p>

            <template v-for="cookie in storage.cookies">
                <div v-if="cookie.category == 'tracking'" v-bind:key="cookie">
                    <div class="bh-cookies-popup-intro-cookies-list-cookie">
                        <div
                            class="bh-cookies-popup-intro-cookies-list-cookie-icon bh-font-cookie bh-cookies-popup-highlight-color"
                            aria-hidden="true"
                        ></div>
                        <hgroup
                            class="bh-cookies-popup-intro-cookies-list-cookie-infos"
                        >
                            <h3
                                class="bh-cookies-popup-intro-cookies-list-cookie-id"
                            >
                                {{ cookie.name }}
                            </h3>
                            <p
                                class="bh-cookies-popup-intro-cookies-list-cookie-name"
                            >
                                {{ findCookieInformation(cookie.name, "name") }}
                                / {{ findMapping("validity") }}:
                                {{ findMapping(cookie.validityReadable) }}
                            </p>
                            <p
                                class="bh-cookies-popup-intro-cookies-list-cookie-description"
                            >
                                {{
                                    findCookieInformation(
                                        cookie.name,
                                        "description"
                                    )
                                }}
                            </p>
                        </hgroup>
                    </div>
                </div>
            </template>
        </section>
    </div>
</template>

<script>
export default {
    name: "Cookies",
    data: function () {
        return {
            status: "loading",
            storage: {},
            config: {},
            language: (
                window.navigator.userLanguage || window.navigator.language
            ).split("-")[0],
            fallbacklanguage: "en",
            pane: "intro",
        };
    },
    methods: {
        loadAll: function () {
            //load general config
            var popup = document.querySelector("#bhCookiePopupConfig");
            var config = popup.getAttribute("data-config");
            config = JSON.parse(config);
            this.config = config;

            this.postAjax(
                "https://cookies.bakehouse.at/api/project/get",
                { token: this.config.token },
                (data) => {
                    (this.status = "good"), (this.storage = JSON.parse(data));
                }
            );
        },
        errorAbort: function () {
            //error go to site and log error
            console.log("error, go to site");
            this.acceptCookies();
            document.cookie =
                "bh_cookies_accepted_by_error=true; Path=/; SameSite=Lax";
            window.location.reload();
        },
        findMapping: function (mapping) {
            if (this.storage.mappings[mapping]) {
                if (this.storage.mappings[mapping][this.language]) {
                    //a translation exists
                    return this.storage.mappings[mapping][this.language];
                } else if (
                    this.storage.mappings[mapping][this.fallbacklanguage]
                ) {
                    //no translation exists, use default language english
                    return this.storage.mappings[mapping][
                        this.fallbacklanguage
                    ];
                } else if (this.storage.mappings[mapping][0]) {
                    //use any language if nothing else exists
                    return this.storage.mappings[mapping][0];
                }
            }
        },
        findCookieInformation: function (cookie, attribute) {
            if (this.storage.cookies[cookie]) {
                if (this.storage.cookies[cookie].description[this.language]) {
                    //a translation exists
                    return this.storage.cookies[cookie].description[
                        this.language
                    ][attribute];
                } else if (
                    this.storage.cookies[cookie].description[
                        this.fallbacklanguage
                    ]
                ) {
                    //no translation exists, use default language english
                    return this.storage.cookies[cookie].description[
                        this.fallbacklanguage
                    ][attribute];
                } else if (this.storage.cookies[cookie].description[0]) {
                    //use any language if nothing else exists
                    return this.storage.cookies[cookie].description[0][
                        attribute
                    ];
                }
            }
        },
        countCategory: function (category) {
            var i = 0;
            for (var cookie in this.storage.cookies) {
                if (this.storage.cookies[cookie]["category"] == category) {
                    i++;
                }
            }
            return i;
        },
        cookiesAllowed: function () {
            return navigator.cookieEnabled;
        },
        postAjax: function (url, data, success) {
            var params =
                typeof data == "string"
                    ? data
                    : Object.keys(data)
                          .map(function (k) {
                              return (
                                  encodeURIComponent(k) +
                                  "=" +
                                  encodeURIComponent(data[k])
                              );
                          })
                          .join("&");

            var xhr = window.XMLHttpRequest
                ? new XMLHttpRequest()
                : new window.ActiveXObject("Microsoft.XMLHTTP");
            xhr.open("POST", url);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    var status = xhr.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (xhr.responseText == "") {
                            console.log("error, go to site");
                            this.acceptCookies();
                            document.cookie =
                                "bh_cookies_accepted_by_error=true; Path=/; SameSite=Lax";
                            window.location.reload();
                            return false;
                        } else {
                            success(xhr.responseText);
                            return true;
                        }
                    }
                }
            };
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            xhr.send(params);
            if (!xhr) {
                console.log("error, go to site");
                this.acceptCookies();
                document.cookie =
                    "bh_cookies_accepted_by_error=true; Path=/; SameSite=Lax";
                window.location.reload();
                return false;
            }
            return xhr;
        },
        getAjax: function (url) {
            var xmlhttp = window.XMLHttpRequest
                ? new XMLHttpRequest()
                : new window.ActiveXObject("Microsoft.XMLHTTP");

            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                    // callback(xmlhttp.responseText);
                }
            };

            xmlhttp.open("GET", url, true);
            xmlhttp.send();
        },
    },
    mounted: function () {
        this.loadAll();
    },
};
</script>

<style></style>
