<template>
    <Cookies />
</template>

<script>
import Cookies from "./components/Cookies.vue";

export default {
    name: "ListApp",
    components: {
        Cookies,
    },
};
</script>

<style></style>
