<template>
    <Popup />
</template>

<script>
import Popup from "./components/Popup.vue";

export default {
    name: "PopupApp",
    components: {
        Popup,
    },
};
</script>

<style></style>
