<template>
    <div
        class="popup bh-cookies-popup"
        v-bind:data-color="config.color"
        v-bind:style="{ '--bh-cookies-popup-color-highlight': config.color }"
    >
        <div
            class="bh-cookies-popup-inner"
            v-bind:class="{ show: storage.mappings }"
        >
            <div
                class="bh-cookies-popup-background"
                v-bind:style="{
                    backgroundImage:
                        'url(https://cookies.bakehouse.at/screenshots/' +
                        config.token +
                        '.jpg)',
                }"
            ></div>
            <transition name="popup">
                <div
                    class="bh-cookies-popup-window"
                    v-if="storage.mappings"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="bh-cookies-popup-window-title"
                    aria-describedby="bh-cookies-popup-content"
                >
                    <header class="bh-cookies-popup-window-header">
                        <div
                            class="bh-cookies-popup-window-icon bh-font-info-circle"
                            aria-hidden="true"
                        ></div>
                        <h1
                            id="bh-cookies-popup-window-title"
                            class="bh-cookies-popup-window-title"
                        >
                            {{ findMapping("windowTitle") }}
                        </h1>
                    </header>
                    <section
                        id="bh-cookies-popup-content"
                        class="bh-cookies-popup-content"
                    >
                        <div
                            class="bh-cookies-popup-content-information"
                            v-if="!cookiesAllowed()"
                        >
                            <div
                                class="bh-cookies-popup-intro-text bh-cookies-popup-bodytext"
                                v-if="isInIframe()"
                            >
                                {{ findMapping("isInIframe") }}
                            </div>
                            <div
                                class="bh-cookies-popup-intro-text bh-cookies-popup-bodytext"
                            >
                                {{ findMapping("cookiesNotAllowed") }}
                            </div>
                        </div>

                        <div
                            class="bh-cookies-popup-intro-logo"
                            v-if="cookiesAllowed()"
                        ></div>

                        <div
                            class="bh-cookies-popup-content-information"
                            v-if="cookiesAllowed()"
                        >
                            <div
                                class="bh-cookies-popup-intro-text bh-cookies-popup-bodytext"
                                v-html="findMapping('introText')"
                            ></div>
                            <div
                                class="bh-cookies-popup-links bh-cookies-popup-bodytext bh-cookies-popup-highlight-color"
                                v-if="
                                    config.imprintLink ||
                                    config.agbLink ||
                                    config.gdrpLink
                                "
                            >
                                <a
                                    class="bh-cookies-popup-link-imprint bh-cookies-popup-link"
                                    v-if="config.imprintLink"
                                    href="/?type=14"
                                    >{{ findMapping("imprintLink") }}</a
                                >
                                <a
                                    class="bh-cookies-popup-link-gdpr bh-cookies-popup-link"
                                    v-if="config.gdprLink"
                                    href="/?type=15"
                                    >{{ findMapping("gdprLink") }}</a
                                >
                                <a
                                    class="bh-cookies-popup-link-agb bh-cookies-popup-link"
                                    v-if="config.agbLink"
                                    href="/?type=16"
                                    >{{ findMapping("agbLink") }}</a
                                >
                            </div>
                            <details class="bh-cookies-show-all-cookies">
                                <summary
                                    class="bh-cookies-show-all-cookies-summary"
                                >
                                    <span>{{
                                        findMapping("openCookies")
                                    }}</span>
                                    <span
                                        class="bh-cookies-show-all-cookies-icon bh-font-plus"
                                        aria-hidden="true"
                                    ></span>
                                </summary>
                                <Cookies />
                            </details>
                        </div>
                    </section>
                    <fieldset
                        class="bh-cookies-category-select"
                        v-if="cookiesAllowed() && config.categorySelection"
                    >
                        <label
                            class="bh-cookies-category-checkbox-label-necessary bh-cookies-category-checkbox-label readonly"
                            ><input
                                type="checkbox"
                                class="bh-cookies-category-checkbox-necessary bh-cookies-category-checkbox"
                                disabled
                                v-model="selectedCategories.necessary"
                            />
                            {{ findMapping("necessary") }}</label
                        >
                        <label
                            class="bh-cookies-category-checkbox-label-statistic bh-cookies-category-checkbox-label"
                            ><input
                                type="checkbox"
                                class="bh-cookies-category-checkbox-statistic bh-cookies-category-checkbox"
                                v-model="selectedCategories.statistic"
                            />
                            {{ findMapping("statistic") }}</label
                        >
                        <label
                            class="bh-cookies-category-checkbox-label-marketing bh-cookies-category-checkbox-label"
                            ><input
                                type="checkbox"
                                class="bh-cookies-category-checkbox-marketing bh-cookies-category-checkbox"
                                v-model="selectedCategories.marketing"
                            />
                            {{ findMapping("marketing") }}</label
                        >
                        <label
                            class="bh-cookies-category-checkbox-label-thirdcountries bh-cookies-category-checkbox-label"
                            ><input
                                type="checkbox"
                                class="bh-cookies-category-checkbox-thirdcountries bh-cookies-category-checkbox"
                                v-model="selectedCategories.thirdcountries"
                            />
                            {{ findMapping("thirdcountries") }}</label
                        >
                    </fieldset>
                    <menu
                        class="bh-cookies-popup-buttons"
                        v-if="cookiesAllowed()"
                    >
                        <li
                            v-if="
                                config.optOutTracking &&
                                !config.categorySelection
                            "
                            class="bh-cookies-popup-button-item-accept-functional bh-cookies-popup-button-item"
                        >
                            <button
                                type="button"
                                class="bh-cookies-popup-accept-functional bh-cookies-popup-button"
                                v-on:click.prevent="acceptOnlyFunctional()"
                                autofocus
                            >
                                {{ findMapping("optOutTrackingButton") }}
                            </button>
                        </li>
                        <li
                            v-if="config.categorySelection"
                            class="bh-cookies-popup-button-item-save-selection bh-cookies-popup-button-item"
                        >
                            <button
                                type="button"
                                class="bh-cookies-popup-save-selection bh-cookies-popup-button"
                                v-on:click.prevent="saveSelection()"
                                autofocus
                            >
                                {{ findMapping("saveSelection") }}
                            </button>
                        </li>
                        <li
                            class="bh-cookies-popup-button-item-accept-all bh-cookies-popup-button-item"
                        >
                            <button
                                type="button"
                                class="bh-cookies-popup-accept-all bh-cookies-popup-button bh-cookies-popup-button-highlight"
                                v-on:click.prevent="acceptAllCookies()"
                                :autofocus="
                                    !config.optOutTracking &&
                                    !config.categorySelection
                                "
                            >
                                {{ findMapping("acceptAllCookies") }}
                            </button>
                        </li>
                    </menu>
                    <menu
                        class="bh-cookies-popup-buttons"
                        v-if="!cookiesAllowed()"
                    >
                        <li
                            class="bh-cookies-popup-button-item-newTab bh-cookies-popup-button-item"
                        >
                            <button
                                type="button"
                                class="bh-cookies-popup-newTab bh-cookies-popup-button bh-cookies-popup-button-highlight"
                                v-on:click.prevent="openInNewTab()"
                                autofocus
                            >
                                {{ findMapping("openInNewTab") }}
                            </button>
                        </li>
                    </menu>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Cookies from "./Cookies.vue";

export default {
    name: "Popup",
    components: {
        Cookies,
    },
    data: function () {
        return {
            status: "loading",
            storage: {},
            config: {},
            language: (
                window.navigator.userLanguage || window.navigator.language
            ).split("-")[0],
            fallbacklanguage: "en",
            pane: "intro",
            selectedCategories: {
                necessary: true,
                statistic: false,
                marketing: false,
                thirdcountries: false,
            },
        };
    },
    methods: {
        acceptCookiesOnlyFunctional: function () {
            var crossSite = "";
            var deleteCookie = "Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";

            if (location.protocol == "https:") {
                crossSite = "SameSite=None; Secure; Partitioned";
                deleteCookie = deleteCookie + "; " + crossSite;
            }

            document.cookie =
                "bh_cookies_only_functional=true; Expires=" +
                new Date(
                    new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                ).toGMTString() +
                "; Path=/;" +
                crossSite;

            /*
             * folgende zeilen code sehen doppelt aus, das hatte aber folgenden Grund:
             * third-party cookie deprecation erforderte "partitioned" für bh_cookies_*
             * löschen funktionierte nur, wenn "partitioned" explizit angegeben wurde
             * bei cookies ohne "partitioned" ging das löschen durch die angabe nicht
             * da bh_cookies_* ohne "partitioned" im umlauf waren (1-jährige gültigtkeit)
             * brauchte es die löschung "doppelt" ohne die angabe von "partitioned"
             * die zeilen mit "deleteCookieDepr" können im Februar 2025
             * daher sicher gelöscht werden können - eventuell war das nur ein bug
             * und löschen funktioniert mittlerweile ohne angabe von "partitioned"
             * dann kann auch deleteCookie = deleteCookie + '; ' + crossSite;
             * entfernt werden - siehe auch saveSelection() function
             */

            var deleteCookieDepr =
                "Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";
            document.cookie = "bh_cookies_allow_all=; " + deleteCookieDepr;
            document.cookie =
                "bh_cookies_allow_marketing=; " + deleteCookieDepr;
            document.cookie =
                "bh_cookies_allow_statistic=; " + deleteCookieDepr;
            document.cookie =
                "bh_cookies_allow_thirdcountries=; " + deleteCookieDepr;

            document.cookie = "bh_cookies_allow_all=; " + deleteCookie;
            document.cookie = "bh_cookies_allow_marketing=; " + deleteCookie;
            document.cookie = "bh_cookies_allow_statistic=; " + deleteCookie;
            document.cookie =
                "bh_cookies_allow_thirdcountries=; " + deleteCookie;
        },
        loadAll: function () {
            //load general config
            var popup = document.querySelector("#bhCookiePopupConfig");
            var config = popup.getAttribute("data-config");
            config = JSON.parse(config);
            this.config = config;

            this.postAjax(
                "https://cookies.bakehouse.at/api/project/get",
                { token: this.config.token },
                (data) => {
                    (this.status = "good"), (this.storage = JSON.parse(data));
                }
            );
        },
        errorAbort: function () {
            //error go to site and log error
            console.log("error, go to site");
            document.cookie =
                "bh_cookies_accepted_by_error=true; Path=/; SameSite=Lax";
            this.saveSelection();
            window.location.reload();
        },
        findMapping: function (mapping) {
            if (this.storage.mappings[mapping]) {
                if (this.storage.mappings[mapping][this.language]) {
                    //a translation exists
                    return this.storage.mappings[mapping][this.language];
                } else if (
                    this.storage.mappings[mapping][this.fallbacklanguage]
                ) {
                    //no translation exists, use default language english
                    return this.storage.mappings[mapping][
                        this.fallbacklanguage
                    ];
                } else if (this.storage.mappings[mapping][0]) {
                    //use any language if nothing else exists
                    return this.storage.mappings[mapping][0];
                }
            }
        },
        findCookieInformation: function (cookie, attribute) {
            if (this.storage.cookies[cookie]) {
                if (this.storage.cookies[cookie].description[this.language]) {
                    //a translation exists
                    return this.storage.cookies[cookie].description[
                        this.language
                    ][attribute];
                } else if (
                    this.storage.cookies[cookie].description[
                        this.fallbacklanguage
                    ]
                ) {
                    //no translation exists, use default language english
                    return this.storage.cookies[cookie].description[
                        this.fallbacklanguage
                    ][attribute];
                } else if (this.storage.cookies[cookie].description[0]) {
                    //use any language if nothing else exists
                    return this.storage.cookies[cookie].description[0][
                        attribute
                    ];
                }
            }
        },
        countCategory: function (category) {
            var i = 0;
            for (var cookie in this.storage.cookies) {
                if (this.storage.cookies[cookie]["category"] == category) {
                    i++;
                }
            }
            return i;
        },
        cookiesAllowed: function () {
            if (this.isInIframe() && this.isSafari()) {
                return false;
            } else {
                return true;
            }
        },
        isInIframe: function () {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
        isSafari: function () {
            var userAgent = navigator.userAgent.toLowerCase();
            if (
                userAgent.includes("safari") &&
                !userAgent.includes("chrome") &&
                !userAgent.includes("crios")
            ) {
                return true;
            }
            return false;
        },
        acceptAllCookies: function () {
            this.selectedCategories.necessary = true;
            this.selectedCategories.statistic = true;
            this.selectedCategories.marketing = true;
            this.selectedCategories.thirdcountries = true;
            this.saveSelection();
        },
        acceptOnlyFunctional: function () {
            this.selectedCategories.necessary = true;
            this.selectedCategories.statistic = false;
            this.selectedCategories.marketing = false;
            this.selectedCategories.thirdcountries = false;
            this.saveSelection();
        },
        saveSelection: function () {
            //save referrer
            sessionStorage.setItem(
                "bhCookiePopupReferrer",
                JSON.stringify({
                    referrer: document.referrer,
                })
            );

            var crossSite = "";
            var deleteCookieDepr =
                "Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";
            var deleteCookie = "Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/";

            if (location.protocol == "https:") {
                crossSite = "SameSite=None; Secure; Partitioned";
                deleteCookie = deleteCookie + "; " + crossSite;
            }

            //cookies saved
            document.cookie =
                "bh_cookies_accepted=true; Expires=" +
                new Date(
                    new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                ).toGMTString() +
                "; Path=/;" +
                crossSite;

            //if only functional is checked
            if (
                !this.selectedCategories.marketing &&
                !this.selectedCategories.statistic &&
                !this.selectedCategories.thirdcountries
            ) {
                document.cookie =
                    "bh_cookies_only_functional=true; Expires=" +
                    new Date(
                        new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                    ).toGMTString() +
                    "; Path=/;" +
                    crossSite;
            } else {
                document.cookie =
                    "bh_cookies_only_functional=; " + deleteCookieDepr;
                document.cookie =
                    "bh_cookies_only_functional=; " + deleteCookie;
            }
            //if all ar checked, allow all
            if (
                this.selectedCategories.marketing &&
                this.selectedCategories.statistic &&
                this.selectedCategories.thirdcountries
            ) {
                document.cookie =
                    "bh_cookies_allow_all=true; Expires=" +
                    new Date(
                        new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                    ).toGMTString() +
                    "; Path=/;" +
                    crossSite;
            } else {
                document.cookie = "bh_cookies_allow_all=; " + deleteCookieDepr;
                document.cookie = "bh_cookies_allow_all=; " + deleteCookie;
            }
            //set cookies if certain categories are selected
            if (this.selectedCategories.marketing) {
                document.cookie =
                    "bh_cookies_allow_marketing=true; Expires=" +
                    new Date(
                        new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                    ).toGMTString() +
                    "; Path=/;" +
                    crossSite;
            } else {
                document.cookie =
                    "bh_cookies_allow_marketing=; " + deleteCookieDepr;
                document.cookie =
                    "bh_cookies_allow_marketing=; " + deleteCookie;
            }
            if (this.selectedCategories.statistic) {
                document.cookie =
                    "bh_cookies_allow_statistic=true; Expires=" +
                    new Date(
                        new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                    ).toGMTString() +
                    "; Path=/;" +
                    crossSite;
            } else {
                document.cookie =
                    "bh_cookies_allow_statistic=; " + deleteCookieDepr;
                document.cookie =
                    "bh_cookies_allow_statistic=; " + deleteCookie;
            }
            if (this.selectedCategories.thirdcountries) {
                document.cookie =
                    "bh_cookies_allow_thirdcountries=true; Expires=" +
                    new Date(
                        new Date().getTime() + 60 * 60 * 1000 * 24 * 365
                    ).toGMTString() +
                    "; Path=/;" +
                    crossSite;
            } else {
                document.cookie =
                    "bh_cookies_allow_thirdcountries=; " + deleteCookieDepr;
                document.cookie =
                    "bh_cookies_allow_thirdcountries=; " + deleteCookie;
            }

            /* false = no tracking */
            const tracking = false;
            if (tracking) {
                var userHadChoice = 0;
                if (this.config.categorySelection) {
                    userHadChoice = 2;
                }
                if (this.config.optOutTracking) {
                    userHadChoice = 1;
                }

                const categories = this.selectedCategories;
                const postData = {
                    bh_cookies_only_functional:
                        !categories.marketing &&
                        !categories.statistic &&
                        !categories.thirdcountries,
                    bh_cookies_allow_all:
                        categories.marketing &&
                        categories.statistic &&
                        categories.thirdcountries,
                    bh_cookies_allow_marketing: categories.marketing,
                    bh_cookies_allow_statistic: categories.statistic,
                    bh_cookies_allow_thirdcountries: categories.thirdcountries,
                    user_had_choice: userHadChoice,
                };

                fetch("https://cookies.bakehouse.at/api/save", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(postData),
                })
                    .then((response) =>
                        response.ok
                            ? response.json()
                            : Promise.reject("Network response was not ok.")
                    )
                    .then((data) => {
                        console.log("Success:", data);
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.error(error);
                        window.location.reload();
                    });
            } else {
                window.location.reload();
            }
        },
        openInNewTab: function () {
            window.open(window.location.href, "_blank");
        },
        postAjax: function (url, data, success) {
            var params =
                typeof data == "string"
                    ? data
                    : Object.keys(data)
                          .map(function (k) {
                              return (
                                  encodeURIComponent(k) +
                                  "=" +
                                  encodeURIComponent(data[k])
                              );
                          })
                          .join("&");

            var xhr = window.XMLHttpRequest
                ? new XMLHttpRequest()
                : new window.ActiveXObject("Microsoft.XMLHTTP");
            xhr.open("POST", url);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    var status = xhr.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (xhr.responseText == "") {
                            console.log("error, go to site");
                            document.cookie =
                                "bh_cookies_accepted_by_error=true; Path=/; SameSite=Lax";
                            this.saveSelection();
                            window.location.reload();
                            return false;
                        } else {
                            success(xhr.responseText);
                            return true;
                        }
                    }
                }
            }.bind(this);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            xhr.send(params);
            if (!xhr) {
                console.log("error, go to site");
                document.cookie =
                    "bh_cookies_accepted_by_error=true; Path=/; SameSite=Lax";
                this.saveSelection();
                window.location.reload();
                return false;
            }
            return xhr;
        },
        getAjax: function (url) {
            var xmlhttp = window.XMLHttpRequest
                ? new XMLHttpRequest()
                : new window.ActiveXObject("Microsoft.XMLHTTP");

            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                    // callback(xmlhttp.responseText);
                }
            };

            xmlhttp.open("GET", url, true);
            xmlhttp.send();
        },
    },
    mounted: function () {
        this.loadAll();

        /* bakehouse branding ascii art */
        console.log(
            "" +
                " __  __           _                 _ _   _          " +
                "\n" +
                "|  \\/  |         | |               (_) | | |         " +
                "\n" +
                "| \\  / | __ _  __| | ___  __      ___| |_| |__       " +
                "\n" +
                "| |\\/| |/ _` |/ _` |/ _ \\ \\ \\ /\\ / / | __| '_ \\      " +
                "\n" +
                "| |  | | (_| | (_| |  __/  \\ V  V /| | |_| | | |     " +
                "\n" +
                "|_|__|_|\\__,_|\\__,_|\\___|   \\_/\\_/ |_|\\__|_| |_|   " +
                "\n\n" +
                " ____        _        _                          " +
                "\n" +
                "|  _ \\      | |      | |                         " +
                "\n" +
                "| |_) | __ _| | _____| |__   ___  _   _ ___  ___ " +
                "\n" +
                "|  _ < / _` | |/ / _ \\ '_ \\ / _ \\| | | / __|/ _ \\" +
                "\n" +
                "| |_) | (_| |   <  __/ | | | (_) | |_| \\__ \\  __/" +
                "\n" +
                "|____/ \\__,_|_|\\_\\___|_| |_|\\___/ \\__,_|___/\\___|" +
                "\n\n\n" +
                "https://www.bakehouse.at/"
        );

        const link = document.createElement("link");
        link.href =
            "/typo3conf/ext/bh/Resources/Public/Css/iconset-frontend.css";
        link.type = "text/css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
    },
};
</script>

<style>
/* fonts */

@font-face {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("/typo3conf/ext/bh/Resources/Public/Fonts/noto-sans/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2")
            format("woff2");
}

@font-face {
    font-family: "Noto Sans";
    font-style: italic;
    font-weight: 400;
    src: local(""),
        url("/typo3conf/ext/bh/Resources/Public/Fonts/noto-sans/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-italic.woff2")
            format("woff2");
}

@font-face {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    src: local(""),
        url("/typo3conf/ext/bh/Resources/Public/Fonts/noto-sans/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2")
            format("woff2");
}

@font-face {
    font-family: "Noto Sans";
    font-style: italic;
    font-weight: 700;
    src: local(""),
        url("/typo3conf/ext/bh/Resources/Public/Fonts/noto-sans/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700italic.woff2")
            format("woff2");
}

/* popup */

.bh-cookies-popup {
    --bh-cookies-popup-spacing-main: clamp(1rem, 0.8239rem + 0.7512vw, 1.5rem);
    --bh-cookies-popup-spacing-content: clamp(
        0.75rem,
        0.662rem + 0.3756vw,
        1rem
    );
    --bh-cookies-popup-icon-size-main: 1.25rem;
    --bh-cookies-popup-icon-size-large: 1.5rem;
    --bh-cookies-popup-border-main: 1px solid #dadada;
    --bh-cookies-popup-color-text-dark: #303030;
    --bh-cookies-popup-color-text-main: #3f3f3f;
    --bh-cookies-popup-color-text-dimmed: #656565;
    --bh-cookies-popup-color-text-lighter: #b4b4b4;
    --bh-cookies-popup-color-text-light: #ffffff;
    --bh-cookies-popup-color-dim: black 10%;
    --bh-cookies-popup-background-darker: #dadada;
    --bh-cookies-popup-background-main: #f3f3f3;
    --bh-cookies-popup-background-light: #ffffff;
    --bh-cookies-popup-shadow-main: 0 0 1.25rem rgba(0, 0, 0, 0.15);
    --bh-cookies-popup-transition-main: 0.4s ease;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: var(--bh-cookies-popup-spacing-main);
    width: 100%;
    height: 100%;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3;
}

.bh-cookies-popup *:focus-visible {
    outline: 1px solid var(--bh-cookies-popup-background-darker);
    outline-offset: 0;
}

.bh-cookies-popup .bh-cookies-popup-highlight-color {
    color: var(--bh-cookies-popup-color-highlight);
}

.bh-cookies-popup .bh-cookies-popup-highlight-background {
    background: var(--bh-cookies-popup-color-highlight);
}

.bh-cookies-popup-inner {
    display: none;
    width: 100%;
    max-width: 660px;
}

.bh-cookies-popup-inner.show {
    display: block;
}

@keyframes popup {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.popup-enter-active {
    animation: popup var(--bh-cookies-popup-transition-main);
}

.popup-leave-active {
    animation: popup var(--bh-cookies-popup-transition-main) reverse;
}

.bh-cookies-popup-background {
    position: fixed;
    inset: -20px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(10px) brightness(0.8);
}

.bh-cookies-popup-window {
    box-sizing: border-box;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bh-cookies-popup-color-highlight) transparent;
    overscroll-behavior: contain;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--bh-cookies-popup-spacing-main);
    border-radius: 0.625rem;
    padding: var(--bh-cookies-popup-spacing-main);
    width: 100%;
    max-height: calc(100svh - var(--bh-cookies-popup-spacing-main) * 2);
    background: var(--bh-cookies-popup-background-main);
    box-shadow: var(--bh-cookies-popup-shadow-main);
    transition: var(--bh-cookies-popup-transition-main);
}

/* -webkit-scrollbar is non-standard and should not be used
   once scrollbar-width and scrollbar-color has sufficient browser support */
.bh-cookies-popup-window::-webkit-scrollbar,
.bh-cookies-popup-window ::-webkit-scrollbar {
    width: 6px;
}
.bh-cookies-popup-window::-webkit-scrollbar-track,
.bh-cookies-popup-window ::-webkit-scrollbar-track {
    background: transparent;
}
.bh-cookies-popup-window::-webkit-scrollbar-thumb,
.bh-cookies-popup-window ::-webkit-scrollbar-thumb {
    background: var(--bh-cookies-popup-color-highlight);
}

/* header */

.bh-cookies-popup-window-header {
    display: grid;
    grid-template-columns: var(--bh-cookies-popup-icon-size-main) 1fr var(
            --bh-cookies-popup-icon-size-main
        );
    align-items: center;
    gap: 0.625rem;
    text-align: center;
    color: var(--bh-cookies-popup-color-text-dimmed);
}

.bh-cookies-popup-window-title {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: clamp(0.875rem, 0.831rem + 0.1878vw, 1rem);
}

.bh-cookies-popup-window-icon {
    font-size: var(--bh-cookies-popup-icon-size-main);
}

/* content */

.bh-cookies-popup-content {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bh-cookies-popup-color-highlight) transparent;
    min-height: 130px;
}

.bh-cookies-popup-bodytext {
    font-size: clamp(0.6875rem, 0.6655rem + 0.0939vw, 0.75rem);
    line-height: 1.5;
    color: var(--bh-cookies-popup-color-text-main);
}

.bh-cookies-popup-intro-logo {
    margin: 0 auto var(--bh-cookies-popup-spacing-content);
    width: 100%;
    max-width: 15.625rem;
    height: 80px;
    background-image: url("/fileadmin/images/logo.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.bh-cookies-popup-intro-text {
    margin-bottom: var(--bh-cookies-popup-spacing-content);
}

.bh-cookies-popup-links {
    margin-bottom: var(--bh-cookies-popup-spacing-content);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bh-cookies-popup-link {
    padding: 0 0.8em;
    line-height: 1.3;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: transparent;
    text-decoration-thickness: 0;
    text-underline-offset: 0.14em;
    color: inherit;
    transition: color var(--bh-cookies-popup-transition-main),
        text-decoration var(--bh-cookies-popup-transition-main);
}

.bh-cookies-popup-link:not(:first-of-type) {
    border-left: var(--bh-cookies-popup-border-main);
}

.bh-cookies-popup-link:focus-visible {
    text-decoration-color: currentcolor;
    text-decoration-thickness: 0.1em;
}

@supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
    .bh-cookies-popup-link:focus-visible {
        color: color-mix(
            in srgb,
            var(--bh-cookies-popup-color-highlight),
            var(--bh-cookies-popup-color-dim)
        );
    }
}

@media (pointer: fine) {
    .bh-cookies-popup-link:hover {
        text-decoration-color: currentcolor;
        text-decoration-thickness: 0.1em;
    }

    @supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
        .bh-cookies-popup-link:hover {
            color: color-mix(
                in srgb,
                var(--bh-cookies-popup-color-highlight),
                var(--bh-cookies-popup-color-dim)
            );
        }
    }
}

/* checkboxes (category select) */

.bh-cookies-category-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    margin: 0;
    border: 0;
    padding: 0;
}

.bh-cookies-category-checkbox-label {
    display: flex;
    gap: 0.6em;
    font-size: clamp(0.75rem, 0.728rem + 0.0939vw, 0.8125rem);
    line-height: 1.5;
    cursor: pointer;
}

.bh-cookies-category-checkbox-label.readonly {
    opacity: 0.6;
    cursor: not-allowed;
}

.bh-cookies-category-checkbox {
    --bh-cookies-category-checkbox-size: 1.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    flex-shrink: 0;
    margin: 0;
    border: 0;
    border-radius: 0.2em;
    padding: 0;
    width: var(--bh-cookies-category-checkbox-size);
    height: var(--bh-cookies-category-checkbox-size);
    background: var(--bh-cookies-popup-background-darker);
    cursor: pointer;
    transition: background var(--bh-cookies-popup-transition-main);
}

@supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
    .bh-cookies-category-checkbox:focus-visible {
        background: color-mix(
            in srgb,
            var(--bh-cookies-popup-background-darker),
            var(--bh-cookies-popup-color-dim)
        );
    }
}

@media (pointer: fine) {
    @supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
        .bh-cookies-category-checkbox:hover {
            background: color-mix(
                in srgb,
                var(--bh-cookies-popup-background-darker),
                var(--bh-cookies-popup-color-dim)
            );
        }
    }
}

@supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
    .bh-cookies-category-checkbox:checked:focus-visible {
        background: color-mix(
            in srgb,
            var(--bh-cookies-popup-color-highlight),
            var(--bh-cookies-popup-color-dim)
        );
    }
}

@media (pointer: fine) {
    @supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
        .bh-cookies-category-checkbox:checked:hover {
            background: color-mix(
                in srgb,
                var(--bh-cookies-popup-color-highlight),
                var(--bh-cookies-popup-color-dim)
            );
        }
    }
}

.bh-cookies-category-checkbox:checked {
    background: var(--bh-cookies-popup-color-highlight);
}

.bh-cookies-category-checkbox-label.readonly .bh-cookies-category-checkbox {
    cursor: not-allowed;
}

/* buttons */

.bh-cookies-popup-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--bh-cookies-popup-spacing-content);
    margin: 0;
    padding: 0;
    list-style: none;
}

.bh-cookies-popup-button-item {
    flex: 1 1 13.75rem;
}

.bh-cookies-popup-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: 0;
    border-radius: 0.3em;
    padding: 0.96em 1.92em;
    width: 100%;
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(0.8125rem, 0.7905rem + 0.0939vw, 0.875rem);
    line-height: 1.3;
    color: var(--bh-cookies-popup-color-text-dark);
    background: var(--bh-cookies-popup-background-darker);
    cursor: pointer;
    transition: background var(--bh-cookies-popup-transition-main);
}

@supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
    .bh-cookies-popup-button:focus-visible {
        background: color-mix(
            in srgb,
            var(--bh-cookies-popup-background-darker),
            var(--bh-cookies-popup-color-dim)
        );
    }
}

@media (pointer: fine) {
    @supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
        .bh-cookies-popup-button:hover {
            background: color-mix(
                in srgb,
                var(--bh-cookies-popup-background-darker),
                var(--bh-cookies-popup-color-dim)
            );
        }
    }
}

.bh-cookies-popup-button-highlight {
    color: var(--bh-cookies-popup-color-text-light);
    background: var(--bh-cookies-popup-color-highlight);
}

@supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
    .bh-cookies-popup-button-highlight:focus-visible {
        background: color-mix(
            in srgb,
            var(--bh-cookies-popup-color-highlight),
            var(--bh-cookies-popup-color-dim)
        );
    }
}

@media (pointer: fine) {
    @supports (color: color-mix(in srgb, #000000, #ffffff 10%)) {
        .bh-cookies-popup-button-highlight:hover {
            background: color-mix(
                in srgb,
                var(--bh-cookies-popup-color-highlight),
                var(--bh-cookies-popup-color-dim)
            );
        }
    }
}

/* details (show cookies) */

.bh-cookies-show-all-cookies {
    margin: 0 0 1px;
    border: 0;
    padding: 0;
}

.bh-cookies-show-all-cookies-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    margin: 0;
    border: 0;
    border-top: var(--bh-cookies-popup-border-main);
    border-bottom: var(--bh-cookies-popup-border-main);
    padding: 0.74em 0.8em;
    font-size: clamp(0.75rem, 0.728rem + 0.0939vw, 0.8125rem);
    line-height: 1.3;
    color: var(--bh-cookies-popup-color-text-dark);
    list-style: none;
    cursor: pointer;
    transition: border var(--bh-cookies-popup-transition-main),
        background var(--bh-cookies-popup-transition-main);
}

.bh-cookies-show-all-cookies-summary:focus-visible {
    background: var(--bh-cookies-popup-background-darker);
}

@media (pointer: fine) {
    .bh-cookies-show-all-cookies-summary:hover {
        background: var(--bh-cookies-popup-background-darker);
    }
}

.bh-cookies-show-all-cookies-summary::marker {
    content: none;
}

.bh-cookies-show-all-cookies-summary::-webkit-details-marker {
    display: none;
}

.bh-cookies-show-all-cookies[open] .bh-cookies-show-all-cookies-summary {
    border-bottom-color: transparent;
}

.bh-cookies-show-all-cookies-icon {
    font-size: var(--bh-cookies-popup-icon-size-large);
    transition: transform var(--bh-cookies-popup-transition-main);
}

.bh-cookies-show-all-cookies[open] .bh-cookies-show-all-cookies-icon {
    transform: rotate(-45deg);
}

/* cookies list */

.bh-cookies-popup-intro-cookies-list {
    position: relative;
    border-radius: 6px;
    padding: 20px 30px;
    background: white;
}

@media only screen and (max-width: 768px) {
    .bh-cookies-popup-intro-cookies-list {
        padding: 15px;
    }
}

.bh-cookies-popup-intro-cookies-list-category-heading {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.3;
}

.bh-cookies-popup-intro-cookies-list-description {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 1.4;
    color: #656565;
}

.bh-cookies-popup-intro-cookies-list-cookie {
    display: flex;
    margin-bottom: 15px;
}

.bh-cookies-popup-intro-cookies-list-cookie-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 20px;
    border-radius: 17px;
    width: 34px;
    height: 34px;
    font-size: 34px;
    color: #81ac41;
}

.bh-cookies-popup-intro-cookies-list-cookie-infos {
    margin-bottom: 10px;
}

.bh-cookies-popup-intro-cookies-list-cookie-id {
    margin-top: 0;
    margin-bottom: 1px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.4;
}

.bh-cookies-popup-intro-cookies-list-cookie-name {
    margin-top: 0;
    margin-bottom: 1px;
    font-size: 11px;
    line-height: 1.4;
    color: #303030;
}

.bh-cookies-popup-intro-cookies-list-cookie-description {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 11px;
    line-height: 1.4;
    color: #b4b4b4;
}
</style>
